import { useState } from 'react';
import fm from 'format-message';
import PropTypes from 'prop-types';
import useAccountQuery from 'queries/useAccountQuery';
import Link from '@activebrands/core-web/components/Link';
import useForm from '@activebrands/core-web/hooks/useForm';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import { signIn } from '@activebrands/core-web/state/auth/actions';
import { useTypedDispatch } from '@activebrands/core-web/state/store';
import InputErrors from 'components/Form/InputErrors';
import ThemeButton from 'components/buttons/ThemeButton';
import LoadingIcon from 'components/icons/LoadingIcon';
import Input from 'components/inputs/Input';
import Paragraph from 'components/text/Paragraph';

const ACT_PASSWORD_LENGTH = process.env.REACT_APP_ACT_PASSWORD_LENGTH;

const fields = [
    ['email', fm('Email'), 'email'],
    ['password', fm('Password'), 'password', { minLength: ACT_PASSWORD_LENGTH }],
];

const StyledLink = styled(Link, {
    textDecoration: 'underline',
});

const SignInForm = ({ $style = {}, onSuccess = () => null, setForm = () => null }) => {
    const dispatch = useTypedDispatch();
    const [{ isLoading, errors }, setState] = useState({ isLoading: false, errors: [] });
    const [css] = useStyletron();

    const { signInLabel } = useAccountQuery();

    const form = useForm(async field => {
        const { email, password } = field;

        setState(prev => ({ ...prev, isLoading: true }));

        const response = await dispatch(signIn({ email, password }));

        if (response?.token) {
            setState(prev => ({ ...prev, email, errors: [], isLoading: false }));

            //After sign in does not update user, authenticaate does.
            onSuccess(response?.isMember);
        } else {
            setState(prev => ({ ...prev, errors: [response], isLoading: false }));
        }
    });

    const handleChange = event => {
        form.errors[event.target.name] ? form.validateInput(event) : undefined;
    };

    return (
        <div className={css($style)}>
            {signInLabel && (
                <Paragraph $style={{ marginBottom: '16px' }} fontKeys="Primary/16_94_-1_700">
                    {signInLabel}
                </Paragraph>
            )}
            <form {...form.props} className={css({ marginTop: '32px' })}>
                {fields.map(([name, label, type, rest]) => (
                    <Input
                        required
                        $style={{ marginTop: '24px' }}
                        errors={form.errors[name]}
                        key={name}
                        label={label}
                        maxLength="150"
                        name={name}
                        errorMessageStyle={{
                            position: 'absolute',
                            bottom: '40px',
                        }}
                        placeholder={label}
                        type={type}
                        {...rest}
                        onChange={handleChange}
                    />
                ))}
                {errors.length > 0 && <InputErrors errors={errors} />}

                <div className={css({ display: 'block', width: '100%', marginTop: '16px' })}>
                    <StyledLink isVolatile role="button" tabIndex="0" onClick={() => setForm('reset-password')}>
                        {fm('Forgot password')}?
                    </StyledLink>
                    <div className={css({ display: 'flex', width: '100%', gap: '8px' })}>
                        <ThemeButton
                            $style={{
                                width: '100%',
                                height: '40px',
                                marginTop: '24px',
                                marginBottom: '16px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                            disabled={isLoading}
                            fontKeys="Misc/14_100_-1_500_UC"
                            size="md"
                            theme="defaultsecondary"
                            onClick={() => setForm('sign-up-step-one')}
                        >
                            {fm('Create account')}
                        </ThemeButton>
                        <ThemeButton
                            $style={{
                                width: '100%',
                                height: '40px',
                                marginTop: '24px',
                                marginBottom: '16px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                            disabled={isLoading}
                            fontKeys="Misc/14_100_-1_500_UC"
                            size="md"
                            theme="default"
                            type="submit"
                        >
                            {isLoading ? (
                                <div
                                    className={css({
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '100%',
                                    })}
                                >
                                    <LoadingIcon />
                                </div>
                            ) : (
                                <Paragraph>{fm('Sign in')}</Paragraph>
                            )}
                        </ThemeButton>
                    </div>
                </div>
            </form>
        </div>
    );
};

SignInForm.propTypes = {
    $style: PropTypes.object,
    onSuccess: PropTypes.func,
    setForm: PropTypes.func,
};

export default SignInForm;
